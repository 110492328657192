import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

import { Player } from '@lottiefiles/react-lottie-player';
import DowntownSoundsLottie from '../../lotties/downtown-sounds'

export default function DowntownSounds() {
    return (
        <Box>
            <Player
                autoplay
                loop
                src={DowntownSoundsLottie}
                style={{ width: '100%' }}
            >
            </Player>
        </Box>
    )
}